/* eslint-disable */
import { AspidaClient, BasicHeaders, dataToURLString } from 'aspida'
import { Methods as Methods0 } from './api/v1/account'
import { Methods as Methods1 } from './api/v1/account_setup_tokens'
import { Methods as Methods2 } from './api/v1/account_setup_tokens/_setup_token@string'
import { Methods as Methods3 } from './api/v1/accounts/me'
import { Methods as Methods4 } from './api/v1/accounts/me/accessible_resources/clients'
import { Methods as Methods5 } from './api/v1/accounts/me/accessible_resources/companies'
import { Methods as Methods6 } from './api/v1/accounts/me/email_change_request'
import { Methods as Methods7 } from './api/v1/accounts/me/notification_counts'
import { Methods as Methods8 } from './api/v1/accounts/me/password'
import { Methods as Methods9 } from './api/v1/accounts/me/sign_in_status'
import { Methods as Methods10 } from './api/v1/accounts/me/subscription/clients'
import { Methods as Methods11 } from './api/v1/clients/_client_id@number'
import { Methods as Methods12 } from './api/v1/clients/_client_id@number/attendance'
import { Methods as Methods13 } from './api/v1/clients/_client_id@number/attendances/fix_payment_requests'
import { Methods as Methods14 } from './api/v1/clients/_client_id@number/attendances/fix_payment_requests/_fix_payment_request_id@number'
import { Methods as Methods15 } from './api/v1/clients/_client_id@number/base'
import { Methods as Methods16 } from './api/v1/clients/_client_id@number/chat_rooms'
import { Methods as Methods17 } from './api/v1/clients/_client_id@number/chat_rooms/_chat_room_uid@string/messages'
import { Methods as Methods18 } from './api/v1/clients/_client_id@number/chat_rooms/_uid@string'
import { Methods as Methods19 } from './api/v1/clients/_client_id@number/chat_rooms/_uid@string/read'
import { Methods as Methods20 } from './api/v1/clients/_client_id@number/client_reviews'
import { Methods as Methods21 } from './api/v1/clients/_client_id@number/client_reviews/evaluation'
import { Methods as Methods22 } from './api/v1/clients/_client_id@number/company/np_buyer_alert'
import { Methods as Methods23 } from './api/v1/clients/_client_id@number/company_classification'
import { Methods as Methods24 } from './api/v1/clients/_client_id@number/confirmed_billing_operations/_confirmed_billing_operation_id@number/detail.pdf'
import { Methods as Methods25 } from './api/v1/clients/_client_id@number/feedbacks'
import { Methods as Methods26 } from './api/v1/clients/_client_id@number/groups'
import { Methods as Methods27 } from './api/v1/clients/_client_id@number/groups/_group_id@number'
import { Methods as Methods28 } from './api/v1/clients/_client_id@number/groups/shared_group_summary'
import { Methods as Methods29 } from './api/v1/clients/_client_id@number/invitation'
import { Methods as Methods30 } from './api/v1/clients/_client_id@number/offering/multidate_posting_operations'
import { Methods as Methods31 } from './api/v1/clients/_client_id@number/offering/multidate_posting_operations/_multidate_posting_operation_id@number/check'
import { Methods as Methods32 } from './api/v1/clients/_client_id@number/offerings'
import { Methods as Methods33 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number'
import { Methods as Methods34 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/close'
import { Methods as Methods35 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/closed_reason'
import { Methods as Methods36 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/multidate_posting_operations'
import { Methods as Methods37 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/unpublished'
import { Methods as Methods38 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/users'
import { Methods as Methods39 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/users/_user_id@number/reject_request'
import { Methods as Methods40 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/users/block_status'
import { Methods as Methods41 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/users/unreviewed'
import { Methods as Methods42 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/users.csv'
import { Methods as Methods43 } from './api/v1/clients/_client_id@number/offerings/calendar'
import { Methods as Methods44 } from './api/v1/clients/_client_id@number/offerings/unreviewed'
import { Methods as Methods45 } from './api/v1/clients/_client_id@number/offerings/wage'
import { Methods as Methods46 } from './api/v1/clients/_client_id@number/offers'
import { Methods as Methods47 } from './api/v1/clients/_client_id@number/offers/_offer_id@number'
import { Methods as Methods48 } from './api/v1/clients/_client_id@number/offers/_offer_id@number/archive'
import { Methods as Methods49 } from './api/v1/clients/_client_id@number/offers/_offer_id@number/offerings'
import { Methods as Methods50 } from './api/v1/clients/_client_id@number/offers/_offer_id@number/offerings/_offering_id@number'
import { Methods as Methods51 } from './api/v1/clients/_client_id@number/offers/_offer_id@number/offerings/_offering_id@number/validate'
import { Methods as Methods52 } from './api/v1/clients/_client_id@number/offers/_offer_id@number/offerings/validate'
import { Methods as Methods53 } from './api/v1/clients/_client_id@number/offers/suggestion'
import { Methods as Methods54 } from './api/v1/clients/_client_id@number/phone_number/verification'
import { Methods as Methods55 } from './api/v1/clients/_client_id@number/statements'
import { Methods as Methods56 } from './api/v1/clients/_client_id@number/statements/details.csv'
import { Methods as Methods57 } from './api/v1/clients/_client_id@number/statements/next_billing'
import { Methods as Methods58 } from './api/v1/clients/_client_id@number/user_block_lists'
import { Methods as Methods59 } from './api/v1/clients/_client_id@number/users/_user_id@number'
import { Methods as Methods60 } from './api/v1/clients/_client_id@number/users/_user_id@number/badges'
import { Methods as Methods61 } from './api/v1/clients/_client_id@number/users/_user_id@number/emergency_contact'
import { Methods as Methods62 } from './api/v1/clients/_client_id@number/users/_user_id@number/graduates'
import { Methods as Methods63 } from './api/v1/clients/_client_id@number/users/_user_id@number/groups'
import { Methods as Methods64 } from './api/v1/clients/_client_id@number/users/_user_id@number/offerings'
import { Methods as Methods65 } from './api/v1/clients/_client_id@number/users/_user_id@number/reviews_from_client'
import { Methods as Methods66 } from './api/v1/clients/_client_id@number/users/_user_id@number/skills'
import { Methods as Methods67 } from './api/v1/companies/_company_id@number'
import { Methods as Methods68 } from './api/v1/companies/_company_id@number/base'
import { Methods as Methods69 } from './api/v1/companies/_company_id@number/clients'
import { Methods as Methods70 } from './api/v1/companies/_company_id@number/confirmed_billing_operations/_confirmed_billing_operation_id@number/detail.pdf'
import { Methods as Methods71 } from './api/v1/companies/_company_id@number/statements'
import { Methods as Methods72 } from './api/v1/companies/_company_id@number/statements/billing_adjustments.csv'
import { Methods as Methods73 } from './api/v1/companies/_company_id@number/statements/billing_transfer_commissions.csv'
import { Methods as Methods74 } from './api/v1/companies/_company_id@number/statements/client_statements.csv'
import { Methods as Methods75 } from './api/v1/companies/_company_id@number/statements/company_statements.csv'
import { Methods as Methods76 } from './api/v1/companies/_company_id@number/statements/details'
import { Methods as Methods77 } from './api/v1/companies/_company_id@number/statements/details/by_client'
import { Methods as Methods78 } from './api/v1/companies/_company_id@number/statements/details/of_client.csv'
import { Methods as Methods79 } from './api/v1/companies/_company_id@number/worker_list_storages'
import { Methods as Methods80 } from './api/v1/companies/_company_id@number/worker_list_storages/_worker_list_storage_id@number'
import { Methods as Methods81 } from './api/v1/master_company_occupations'
import { Methods as Methods82 } from './api/v1/notices'
import { Methods as Methods83 } from './api/v1/notices/_notice_id@number/read'
import { Methods as Methods84 } from './api/v1/occupations'
import { Methods as Methods85 } from './api/v1/occupations/_occupation_id@number/skills'
import { Methods as Methods86 } from './api/v1/sign_in'
import { Methods as Methods87 } from './api/v1/sign_in/confirm'
import { Methods as Methods88 } from './api/v1/sign_in/refresh'
import { Methods as Methods89 } from './api/v1/sign_out'
import { Methods as Methods90 } from './api/v1/tags'
import { Methods as Methods91 } from './api/v2/clients/_client_id@number/offerings/_offering_id@number/users/reviews'
import { Methods as Methods92 } from './api/v2/clients/_client_id@number/users'

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? '' : baseURL).replace(/\/$/, '')
  const PATH0 = '/app/api/v1/account'
  const PATH1 = '/app/api/v1/account_setup_tokens'
  const PATH2 = '/app/api/v1/accounts/me'
  const PATH3 = '/app/api/v1/accounts/me/accessible_resources/clients'
  const PATH4 = '/app/api/v1/accounts/me/accessible_resources/companies'
  const PATH5 = '/app/api/v1/accounts/me/email_change_request'
  const PATH6 = '/app/api/v1/accounts/me/notification_counts'
  const PATH7 = '/app/api/v1/accounts/me/password'
  const PATH8 = '/app/api/v1/accounts/me/sign_in_status'
  const PATH9 = '/app/api/v1/accounts/me/subscription/clients'
  const PATH10 = '/app/api/v1/clients'
  const PATH11 = '/attendance'
  const PATH12 = '/attendances/fix_payment_requests'
  const PATH13 = '/base'
  const PATH14 = '/chat_rooms'
  const PATH15 = '/messages'
  const PATH16 = '/read'
  const PATH17 = '/client_reviews'
  const PATH18 = '/client_reviews/evaluation'
  const PATH19 = '/company/np_buyer_alert'
  const PATH20 = '/company_classification'
  const PATH21 = '/confirmed_billing_operations'
  const PATH22 = '/detail.pdf'
  const PATH23 = '/feedbacks'
  const PATH24 = '/groups'
  const PATH25 = '/groups/shared_group_summary'
  const PATH26 = '/invitation'
  const PATH27 = '/offering/multidate_posting_operations'
  const PATH28 = '/check'
  const PATH29 = '/offerings'
  const PATH30 = '/close'
  const PATH31 = '/closed_reason'
  const PATH32 = '/multidate_posting_operations'
  const PATH33 = '/unpublished'
  const PATH34 = '/users'
  const PATH35 = '/reject_request'
  const PATH36 = '/users/block_status'
  const PATH37 = '/users/unreviewed'
  const PATH38 = '/users.csv'
  const PATH39 = '/offerings/calendar'
  const PATH40 = '/offerings/unreviewed'
  const PATH41 = '/offerings/wage'
  const PATH42 = '/offers'
  const PATH43 = '/archive'
  const PATH44 = '/validate'
  const PATH45 = '/offerings/validate'
  const PATH46 = '/offers/suggestion'
  const PATH47 = '/phone_number/verification'
  const PATH48 = '/statements'
  const PATH49 = '/statements/details.csv'
  const PATH50 = '/statements/next_billing'
  const PATH51 = '/user_block_lists'
  const PATH52 = '/badges'
  const PATH53 = '/emergency_contact'
  const PATH54 = '/graduates'
  const PATH55 = '/reviews_from_client'
  const PATH56 = '/skills'
  const PATH57 = '/app/api/v1/companies'
  const PATH58 = '/clients'
  const PATH59 = '/statements/billing_adjustments.csv'
  const PATH60 = '/statements/billing_transfer_commissions.csv'
  const PATH61 = '/statements/client_statements.csv'
  const PATH62 = '/statements/company_statements.csv'
  const PATH63 = '/statements/details'
  const PATH64 = '/statements/details/by_client'
  const PATH65 = '/statements/details/of_client.csv'
  const PATH66 = '/worker_list_storages'
  const PATH67 = '/app/api/v1/master_company_occupations'
  const PATH68 = '/app/api/v1/notices'
  const PATH69 = '/app/api/v1/occupations'
  const PATH70 = '/app/api/v1/sign_in'
  const PATH71 = '/app/api/v1/sign_in/confirm'
  const PATH72 = '/app/api/v1/sign_in/refresh'
  const PATH73 = '/app/api/v1/sign_out'
  const PATH74 = '/app/api/v1/tags'
  const PATH75 = '/app/api/v2/clients'
  const PATH76 = '/users/reviews'
  const GET = 'GET'
  const POST = 'POST'
  const PUT = 'PUT'
  const DELETE = 'DELETE'
  const PATCH = 'PATCH'

  return {
    api: {
      v1: {
        account: {
          get: (option?: { headers?: Methods0['get']['reqHeaders'], config?: T }) =>
            fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json(),
          $get: (option?: { headers?: Methods0['get']['reqHeaders'], config?: T }) =>
            fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH0}`
        },
        account_setup_tokens: {
          _setup_token: (val0: string) => {
            const prefix0 = `${PATH1}/${val0}`

            return {
              get: (option?: { headers?: Methods2['get']['reqHeaders'], config?: T }) =>
                fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, prefix0, GET, option).json(),
              $get: (option?: { headers?: Methods2['get']['reqHeaders'], config?: T }) =>
                fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, prefix0, GET, option).json().then(r => r.body),
              patch: (option: { body: Methods2['patch']['reqBody'], headers?: Methods2['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods2['patch']['status']>(prefix, prefix0, PATCH, option).send(),
              $patch: (option: { body: Methods2['patch']['reqBody'], headers?: Methods2['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods2['patch']['status']>(prefix, prefix0, PATCH, option).send().then(r => r.body),
              $path: () => `${prefix}${prefix0}`
            }
          },
          post: (option: { body: Methods1['post']['reqBody'], headers?: Methods1['post']['reqHeaders'], config?: T }) =>
            fetch<void, BasicHeaders, Methods1['post']['status']>(prefix, PATH1, POST, option).send(),
          $post: (option: { body: Methods1['post']['reqBody'], headers?: Methods1['post']['reqHeaders'], config?: T }) =>
            fetch<void, BasicHeaders, Methods1['post']['status']>(prefix, PATH1, POST, option).send().then(r => r.body),
          $path: () => `${prefix}${PATH1}`
        },
        accounts: {
          me: {
            accessible_resources: {
              clients: {
                get: (option?: { headers?: Methods4['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods4['get']['resBody'], BasicHeaders, Methods4['get']['status']>(prefix, PATH3, GET, option).json(),
                $get: (option?: { headers?: Methods4['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods4['get']['resBody'], BasicHeaders, Methods4['get']['status']>(prefix, PATH3, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH3}`
              },
              companies: {
                get: (option?: { headers?: Methods5['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods5['get']['resBody'], BasicHeaders, Methods5['get']['status']>(prefix, PATH4, GET, option).json(),
                $get: (option?: { headers?: Methods5['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods5['get']['resBody'], BasicHeaders, Methods5['get']['status']>(prefix, PATH4, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH4}`
              }
            },
            email_change_request: {
              post: (option: { body: Methods6['post']['reqBody'], headers?: Methods6['post']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods6['post']['status']>(prefix, PATH5, POST, option).send(),
              $post: (option: { body: Methods6['post']['reqBody'], headers?: Methods6['post']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods6['post']['status']>(prefix, PATH5, POST, option).send().then(r => r.body),
              patch: (option: { body: Methods6['patch']['reqBody'], headers?: Methods6['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods6['patch']['status']>(prefix, PATH5, PATCH, option).send(),
              $patch: (option: { body: Methods6['patch']['reqBody'], headers?: Methods6['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods6['patch']['status']>(prefix, PATH5, PATCH, option).send().then(r => r.body),
              $path: () => `${prefix}${PATH5}`
            },
            notification_counts: {
              get: (option?: { headers?: Methods7['get']['reqHeaders'], config?: T }) =>
                fetch<Methods7['get']['resBody'], BasicHeaders, Methods7['get']['status']>(prefix, PATH6, GET, option).json(),
              $get: (option?: { headers?: Methods7['get']['reqHeaders'], config?: T }) =>
                fetch<Methods7['get']['resBody'], BasicHeaders, Methods7['get']['status']>(prefix, PATH6, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${PATH6}`
            },
            password: {
              patch: (option: { body: Methods8['patch']['reqBody'], headers?: Methods8['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods8['patch']['status']>(prefix, PATH7, PATCH, option).send(),
              $patch: (option: { body: Methods8['patch']['reqBody'], headers?: Methods8['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods8['patch']['status']>(prefix, PATH7, PATCH, option).send().then(r => r.body),
              $path: () => `${prefix}${PATH7}`
            },
            sign_in_status: {
              get: (option?: { headers?: Methods9['get']['reqHeaders'], config?: T }) =>
                fetch<Methods9['get']['resBody'], BasicHeaders, Methods9['get']['status']>(prefix, PATH8, GET, option).json(),
              $get: (option?: { headers?: Methods9['get']['reqHeaders'], config?: T }) =>
                fetch<Methods9['get']['resBody'], BasicHeaders, Methods9['get']['status']>(prefix, PATH8, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${PATH8}`
            },
            subscription: {
              clients: {
                get: (option?: { headers?: Methods10['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods10['get']['resBody'], BasicHeaders, Methods10['get']['status']>(prefix, PATH9, GET, option).json(),
                $get: (option?: { headers?: Methods10['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods10['get']['resBody'], BasicHeaders, Methods10['get']['status']>(prefix, PATH9, GET, option).json().then(r => r.body),
                put: (option: { body: Methods10['put']['reqBody'], headers?: Methods10['put']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods10['put']['status']>(prefix, PATH9, PUT, option).send(),
                $put: (option: { body: Methods10['put']['reqBody'], headers?: Methods10['put']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods10['put']['status']>(prefix, PATH9, PUT, option).send().then(r => r.body),
                $path: () => `${prefix}${PATH9}`
              }
            },
            get: (option?: { headers?: Methods3['get']['reqHeaders'], config?: T }) =>
              fetch<Methods3['get']['resBody'], BasicHeaders, Methods3['get']['status']>(prefix, PATH2, GET, option).json(),
            $get: (option?: { headers?: Methods3['get']['reqHeaders'], config?: T }) =>
              fetch<Methods3['get']['resBody'], BasicHeaders, Methods3['get']['status']>(prefix, PATH2, GET, option).json().then(r => r.body),
            patch: (option: { body: Methods3['patch']['reqBody'], headers?: Methods3['patch']['reqHeaders'], config?: T }) =>
              fetch<void, BasicHeaders, Methods3['patch']['status']>(prefix, PATH2, PATCH, option).send(),
            $patch: (option: { body: Methods3['patch']['reqBody'], headers?: Methods3['patch']['reqHeaders'], config?: T }) =>
              fetch<void, BasicHeaders, Methods3['patch']['status']>(prefix, PATH2, PATCH, option).send().then(r => r.body),
            $path: () => `${prefix}${PATH2}`
          }
        },
        clients: {
          _client_id: (val1: number) => {
            const prefix1 = `${PATH10}/${val1}`

            return {
              attendance: {
                get: (option?: { headers?: Methods12['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods12['get']['resBody'], BasicHeaders, Methods12['get']['status']>(prefix, `${prefix1}${PATH11}`, GET, option).json(),
                $get: (option?: { headers?: Methods12['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods12['get']['resBody'], BasicHeaders, Methods12['get']['status']>(prefix, `${prefix1}${PATH11}`, GET, option).json().then(r => r.body),
                post: (option?: { headers?: Methods12['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods12['post']['resBody'], BasicHeaders, Methods12['post']['status']>(prefix, `${prefix1}${PATH11}`, POST, option).json(),
                $post: (option?: { headers?: Methods12['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods12['post']['resBody'], BasicHeaders, Methods12['post']['status']>(prefix, `${prefix1}${PATH11}`, POST, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH11}`
              },
              attendances: {
                fix_payment_requests: {
                  _fix_payment_request_id: (val2: number) => {
                    const prefix2 = `${prefix1}${PATH12}/${val2}`

                    return {
                      patch: (option: { body: Methods14['patch']['reqBody'], headers?: Methods14['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods14['patch']['status']>(prefix, prefix2, PATCH, option).send(),
                      $patch: (option: { body: Methods14['patch']['reqBody'], headers?: Methods14['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods14['patch']['status']>(prefix, prefix2, PATCH, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix2}`
                    }
                  },
                  get: (option: { query: Methods13['get']['query'], headers?: Methods13['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods13['get']['resBody'], BasicHeaders, Methods13['get']['status']>(prefix, `${prefix1}${PATH12}`, GET, option).json(),
                  $get: (option: { query: Methods13['get']['query'], headers?: Methods13['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods13['get']['resBody'], BasicHeaders, Methods13['get']['status']>(prefix, `${prefix1}${PATH12}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods13['get']['query'] }) =>
                    `${prefix}${prefix1}${PATH12}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                }
              },
              base: {
                get: (option?: { headers?: Methods15['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods15['get']['resBody'], BasicHeaders, Methods15['get']['status']>(prefix, `${prefix1}${PATH13}`, GET, option).json(),
                $get: (option?: { headers?: Methods15['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods15['get']['resBody'], BasicHeaders, Methods15['get']['status']>(prefix, `${prefix1}${PATH13}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH13}`
              },
              chat_rooms: {
                _chat_room_uid: (val3: string) => {
                  const prefix3 = `${prefix1}${PATH14}/${val3}`

                  return {
                    messages: {
                      post: (option: { body: Methods17['post']['reqBody'], headers?: Methods17['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods17['post']['status']>(prefix, `${prefix3}${PATH15}`, POST, option).send(),
                      $post: (option: { body: Methods17['post']['reqBody'], headers?: Methods17['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods17['post']['status']>(prefix, `${prefix3}${PATH15}`, POST, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix3}${PATH15}`
                    }
                  }
                },
                _uid: (val4: string) => {
                  const prefix4 = `${prefix1}${PATH14}/${val4}`

                  return {
                    read: {
                      post: (option?: { headers?: Methods19['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods19['post']['status']>(prefix, `${prefix4}${PATH16}`, POST, option).send(),
                      $post: (option?: { headers?: Methods19['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods19['post']['status']>(prefix, `${prefix4}${PATH16}`, POST, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix4}${PATH16}`
                    },
                    get: (option?: { headers?: Methods18['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods18['get']['resBody'], BasicHeaders, Methods18['get']['status']>(prefix, prefix4, GET, option).json(),
                    $get: (option?: { headers?: Methods18['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods18['get']['resBody'], BasicHeaders, Methods18['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}`
                  }
                },
                get: (option: { query: Methods16['get']['query'], headers?: Methods16['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods16['get']['resBody'], BasicHeaders, Methods16['get']['status']>(prefix, `${prefix1}${PATH14}`, GET, option).json(),
                $get: (option: { query: Methods16['get']['query'], headers?: Methods16['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods16['get']['resBody'], BasicHeaders, Methods16['get']['status']>(prefix, `${prefix1}${PATH14}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods16['get']['query'] }) =>
                  `${prefix}${prefix1}${PATH14}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              client_reviews: {
                evaluation: {
                  get: (option?: { headers?: Methods21['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods21['get']['resBody'], BasicHeaders, Methods21['get']['status']>(prefix, `${prefix1}${PATH18}`, GET, option).json(),
                  $get: (option?: { headers?: Methods21['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods21['get']['resBody'], BasicHeaders, Methods21['get']['status']>(prefix, `${prefix1}${PATH18}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH18}`
                },
                get: (option: { query: Methods20['get']['query'], headers?: Methods20['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods20['get']['resBody'], BasicHeaders, Methods20['get']['status']>(prefix, `${prefix1}${PATH17}`, GET, option).json(),
                $get: (option: { query: Methods20['get']['query'], headers?: Methods20['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods20['get']['resBody'], BasicHeaders, Methods20['get']['status']>(prefix, `${prefix1}${PATH17}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods20['get']['query'] }) =>
                  `${prefix}${prefix1}${PATH17}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              company: {
                np_buyer_alert: {
                  get: (option?: { headers?: Methods22['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods22['get']['resBody'], BasicHeaders, Methods22['get']['status']>(prefix, `${prefix1}${PATH19}`, GET, option).json(),
                  $get: (option?: { headers?: Methods22['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods22['get']['resBody'], BasicHeaders, Methods22['get']['status']>(prefix, `${prefix1}${PATH19}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH19}`
                }
              },
              company_classification: {
                get: (option?: { headers?: Methods23['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods23['get']['resBody'], BasicHeaders, Methods23['get']['status']>(prefix, `${prefix1}${PATH20}`, GET, option).json(),
                $get: (option?: { headers?: Methods23['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods23['get']['resBody'], BasicHeaders, Methods23['get']['status']>(prefix, `${prefix1}${PATH20}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH20}`
              },
              confirmed_billing_operations: {
                _confirmed_billing_operation_id: (val5: number) => {
                  const prefix5 = `${prefix1}${PATH21}/${val5}`

                  return {
                    detail_pdf: {
                      get: (option?: { headers?: Methods24['get']['reqHeaders'], config?: T }) =>
                        fetch<void, Methods24['get']['resHeaders'], Methods24['get']['status']>(prefix, `${prefix5}${PATH22}`, GET, option).send(),
                      $get: (option?: { headers?: Methods24['get']['reqHeaders'], config?: T }) =>
                        fetch<void, Methods24['get']['resHeaders'], Methods24['get']['status']>(prefix, `${prefix5}${PATH22}`, GET, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH22}`
                    }
                  }
                }
              },
              feedbacks: {
                post: (option: { body: Methods25['post']['reqBody'], headers?: Methods25['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods25['post']['status']>(prefix, `${prefix1}${PATH23}`, POST, option).send(),
                $post: (option: { body: Methods25['post']['reqBody'], headers?: Methods25['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods25['post']['status']>(prefix, `${prefix1}${PATH23}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH23}`
              },
              groups: {
                _group_id: (val6: number) => {
                  const prefix6 = `${prefix1}${PATH24}/${val6}`

                  return {
                    get: (option?: { headers?: Methods27['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods27['get']['resBody'], BasicHeaders, Methods27['get']['status']>(prefix, prefix6, GET, option).json(),
                    $get: (option?: { headers?: Methods27['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods27['get']['resBody'], BasicHeaders, Methods27['get']['status']>(prefix, prefix6, GET, option).json().then(r => r.body),
                    patch: (option: { body: Methods27['patch']['reqBody'], headers?: Methods27['patch']['reqHeaders'], config?: T }) =>
                      fetch<Methods27['patch']['resBody'], BasicHeaders, Methods27['patch']['status']>(prefix, prefix6, PATCH, option).json(),
                    $patch: (option: { body: Methods27['patch']['reqBody'], headers?: Methods27['patch']['reqHeaders'], config?: T }) =>
                      fetch<Methods27['patch']['resBody'], BasicHeaders, Methods27['patch']['status']>(prefix, prefix6, PATCH, option).json().then(r => r.body),
                    delete: (option?: { query?: Methods27['delete']['query'], headers?: Methods27['delete']['reqHeaders'], config?: T }) =>
                      fetch<void, BasicHeaders, Methods27['delete']['status']>(prefix, prefix6, DELETE, option).send(),
                    $delete: (option?: { query?: Methods27['delete']['query'], headers?: Methods27['delete']['reqHeaders'], config?: T }) =>
                      fetch<void, BasicHeaders, Methods27['delete']['status']>(prefix, prefix6, DELETE, option).send().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods27['delete']['query'] }) =>
                      `${prefix}${prefix6}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                shared_group_summary: {
                  get: (option?: { headers?: Methods28['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods28['get']['resBody'], BasicHeaders, Methods28['get']['status']>(prefix, `${prefix1}${PATH25}`, GET, option).json(),
                  $get: (option?: { headers?: Methods28['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods28['get']['resBody'], BasicHeaders, Methods28['get']['status']>(prefix, `${prefix1}${PATH25}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH25}`
                },
                get: (option?: { query?: Methods26['get']['query'], headers?: Methods26['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods26['get']['resBody'], BasicHeaders, Methods26['get']['status']>(prefix, `${prefix1}${PATH24}`, GET, option).json(),
                $get: (option?: { query?: Methods26['get']['query'], headers?: Methods26['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods26['get']['resBody'], BasicHeaders, Methods26['get']['status']>(prefix, `${prefix1}${PATH24}`, GET, option).json().then(r => r.body),
                post: (option: { body: Methods26['post']['reqBody'], headers?: Methods26['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods26['post']['resBody'], BasicHeaders, Methods26['post']['status']>(prefix, `${prefix1}${PATH24}`, POST, option).json(),
                $post: (option: { body: Methods26['post']['reqBody'], headers?: Methods26['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods26['post']['resBody'], BasicHeaders, Methods26['post']['status']>(prefix, `${prefix1}${PATH24}`, POST, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods26['get']['query'] }) =>
                  `${prefix}${prefix1}${PATH24}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              invitation: {
                get: (option?: { headers?: Methods29['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods29['get']['resBody'], BasicHeaders, Methods29['get']['status']>(prefix, `${prefix1}${PATH26}`, GET, option).json(),
                $get: (option?: { headers?: Methods29['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods29['get']['resBody'], BasicHeaders, Methods29['get']['status']>(prefix, `${prefix1}${PATH26}`, GET, option).json().then(r => r.body),
                post: (option?: { headers?: Methods29['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods29['post']['resBody'], BasicHeaders, Methods29['post']['status']>(prefix, `${prefix1}${PATH26}`, POST, option).json(),
                $post: (option?: { headers?: Methods29['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods29['post']['resBody'], BasicHeaders, Methods29['post']['status']>(prefix, `${prefix1}${PATH26}`, POST, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH26}`
              },
              offering: {
                multidate_posting_operations: {
                  _multidate_posting_operation_id: (val7: number) => {
                    const prefix7 = `${prefix1}${PATH27}/${val7}`

                    return {
                      check: {
                        patch: (option?: { headers?: Methods31['patch']['reqHeaders'], config?: T }) =>
                          fetch<Methods31['patch']['resBody'], BasicHeaders, Methods31['patch']['status']>(prefix, `${prefix7}${PATH28}`, PATCH, option).json(),
                        $patch: (option?: { headers?: Methods31['patch']['reqHeaders'], config?: T }) =>
                          fetch<Methods31['patch']['resBody'], BasicHeaders, Methods31['patch']['status']>(prefix, `${prefix7}${PATH28}`, PATCH, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix7}${PATH28}`
                      }
                    }
                  },
                  get: (option?: { headers?: Methods30['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods30['get']['resBody'], BasicHeaders, Methods30['get']['status']>(prefix, `${prefix1}${PATH27}`, GET, option).json(),
                  $get: (option?: { headers?: Methods30['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods30['get']['resBody'], BasicHeaders, Methods30['get']['status']>(prefix, `${prefix1}${PATH27}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH27}`
                }
              },
              offerings: {
                _offering_id: (val8: number) => {
                  const prefix8 = `${prefix1}${PATH29}/${val8}`

                  return {
                    close: {
                      patch: (option?: { headers?: Methods34['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods34['patch']['status']>(prefix, `${prefix8}${PATH30}`, PATCH, option).send(),
                      $patch: (option?: { headers?: Methods34['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods34['patch']['status']>(prefix, `${prefix8}${PATH30}`, PATCH, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix8}${PATH30}`
                    },
                    closed_reason: {
                      post: (option: { body: Methods35['post']['reqBody'], headers?: Methods35['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods35['post']['status']>(prefix, `${prefix8}${PATH31}`, POST, option).send(),
                      $post: (option: { body: Methods35['post']['reqBody'], headers?: Methods35['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods35['post']['status']>(prefix, `${prefix8}${PATH31}`, POST, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix8}${PATH31}`
                    },
                    multidate_posting_operations: {
                      post: (option: { body: Methods36['post']['reqBody'], headers?: Methods36['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods36['post']['status']>(prefix, `${prefix8}${PATH32}`, POST, option).send(),
                      $post: (option: { body: Methods36['post']['reqBody'], headers?: Methods36['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods36['post']['status']>(prefix, `${prefix8}${PATH32}`, POST, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix8}${PATH32}`
                    },
                    unpublished: {
                      patch: (option?: { headers?: Methods37['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods37['patch']['status']>(prefix, `${prefix8}${PATH33}`, PATCH, option).send(),
                      $patch: (option?: { headers?: Methods37['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods37['patch']['status']>(prefix, `${prefix8}${PATH33}`, PATCH, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix8}${PATH33}`
                    },
                    users: {
                      _user_id: (val9: number) => {
                        const prefix9 = `${prefix8}${PATH34}/${val9}`

                        return {
                          reject_request: {
                            post: (option: { body: Methods39['post']['reqBody'], headers?: Methods39['post']['reqHeaders'], config?: T }) =>
                              fetch<Methods39['post']['resBody'], BasicHeaders, Methods39['post']['status']>(prefix, `${prefix9}${PATH35}`, POST, option).json(),
                            $post: (option: { body: Methods39['post']['reqBody'], headers?: Methods39['post']['reqHeaders'], config?: T }) =>
                              fetch<Methods39['post']['resBody'], BasicHeaders, Methods39['post']['status']>(prefix, `${prefix9}${PATH35}`, POST, option).json().then(r => r.body),
                            $path: () => `${prefix}${prefix9}${PATH35}`
                          }
                        }
                      },
                      block_status: {
                        get: (option: { query: Methods40['get']['query'], headers?: Methods40['get']['reqHeaders'], config?: T }) =>
                          fetch<Methods40['get']['resBody'], BasicHeaders, Methods40['get']['status']>(prefix, `${prefix8}${PATH36}`, GET, option).json(),
                        $get: (option: { query: Methods40['get']['query'], headers?: Methods40['get']['reqHeaders'], config?: T }) =>
                          fetch<Methods40['get']['resBody'], BasicHeaders, Methods40['get']['status']>(prefix, `${prefix8}${PATH36}`, GET, option).json().then(r => r.body),
                        $path: (option?: { method?: 'get'; query: Methods40['get']['query'] }) =>
                          `${prefix}${prefix8}${PATH36}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                      },
                      unreviewed: {
                        get: (option?: { headers?: Methods41['get']['reqHeaders'], config?: T }) =>
                          fetch<Methods41['get']['resBody'], BasicHeaders, Methods41['get']['status']>(prefix, `${prefix8}${PATH37}`, GET, option).json(),
                        $get: (option?: { headers?: Methods41['get']['reqHeaders'], config?: T }) =>
                          fetch<Methods41['get']['resBody'], BasicHeaders, Methods41['get']['status']>(prefix, `${prefix8}${PATH37}`, GET, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix8}${PATH37}`
                      },
                      get: (option?: { headers?: Methods38['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods38['get']['resBody'], BasicHeaders, Methods38['get']['status']>(prefix, `${prefix8}${PATH34}`, GET, option).json(),
                      $get: (option?: { headers?: Methods38['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods38['get']['resBody'], BasicHeaders, Methods38['get']['status']>(prefix, `${prefix8}${PATH34}`, GET, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix8}${PATH34}`
                    },
                    users_csv: {
                      get: (option?: { headers?: Methods42['get']['reqHeaders'], config?: T }) =>
                        fetch<void, Methods42['get']['resHeaders'], Methods42['get']['status']>(prefix, `${prefix8}${PATH38}`, GET, option).send(),
                      $get: (option?: { headers?: Methods42['get']['reqHeaders'], config?: T }) =>
                        fetch<void, Methods42['get']['resHeaders'], Methods42['get']['status']>(prefix, `${prefix8}${PATH38}`, GET, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix8}${PATH38}`
                    },
                    get: (option?: { headers?: Methods33['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods33['get']['resBody'], BasicHeaders, Methods33['get']['status']>(prefix, prefix8, GET, option).json(),
                    $get: (option?: { headers?: Methods33['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods33['get']['resBody'], BasicHeaders, Methods33['get']['status']>(prefix, prefix8, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix8}`
                  }
                },
                calendar: {
                  get: (option: { query: Methods43['get']['query'], headers?: Methods43['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods43['get']['resBody'], BasicHeaders, Methods43['get']['status']>(prefix, `${prefix1}${PATH39}`, GET, option).json(),
                  $get: (option: { query: Methods43['get']['query'], headers?: Methods43['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods43['get']['resBody'], BasicHeaders, Methods43['get']['status']>(prefix, `${prefix1}${PATH39}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods43['get']['query'] }) =>
                    `${prefix}${prefix1}${PATH39}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                unreviewed: {
                  get: (option?: { headers?: Methods44['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods44['get']['resBody'], BasicHeaders, Methods44['get']['status']>(prefix, `${prefix1}${PATH40}`, GET, option).json(),
                  $get: (option?: { headers?: Methods44['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods44['get']['resBody'], BasicHeaders, Methods44['get']['status']>(prefix, `${prefix1}${PATH40}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH40}`
                },
                wage: {
                  get: (option: { query: Methods45['get']['query'], headers?: Methods45['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods45['get']['resBody'], BasicHeaders, Methods45['get']['status']>(prefix, `${prefix1}${PATH41}`, GET, option).json(),
                  $get: (option: { query: Methods45['get']['query'], headers?: Methods45['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods45['get']['resBody'], BasicHeaders, Methods45['get']['status']>(prefix, `${prefix1}${PATH41}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods45['get']['query'] }) =>
                    `${prefix}${prefix1}${PATH41}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                get: (option: { query: Methods32['get']['query'], headers?: Methods32['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods32['get']['resBody'], BasicHeaders, Methods32['get']['status']>(prefix, `${prefix1}${PATH29}`, GET, option).json(),
                $get: (option: { query: Methods32['get']['query'], headers?: Methods32['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods32['get']['resBody'], BasicHeaders, Methods32['get']['status']>(prefix, `${prefix1}${PATH29}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods32['get']['query'] }) =>
                  `${prefix}${prefix1}${PATH29}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              offers: {
                _offer_id: (val10: number) => {
                  const prefix10 = `${prefix1}${PATH42}/${val10}`

                  return {
                    archive: {
                      patch: (option?: { headers?: Methods48['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods48['patch']['status']>(prefix, `${prefix10}${PATH43}`, PATCH, option).send(),
                      $patch: (option?: { headers?: Methods48['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods48['patch']['status']>(prefix, `${prefix10}${PATH43}`, PATCH, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix10}${PATH43}`
                    },
                    offerings: {
                      _offering_id: (val11: number) => {
                        const prefix11 = `${prefix10}${PATH29}/${val11}`

                        return {
                          validate: {
                            post: (option: { body: Methods51['post']['reqBody'], headers?: Methods51['post']['reqHeaders'], config?: T }) =>
                              fetch<void, BasicHeaders, Methods51['post']['status']>(prefix, `${prefix11}${PATH44}`, POST, option).send(),
                            $post: (option: { body: Methods51['post']['reqBody'], headers?: Methods51['post']['reqHeaders'], config?: T }) =>
                              fetch<void, BasicHeaders, Methods51['post']['status']>(prefix, `${prefix11}${PATH44}`, POST, option).send().then(r => r.body),
                            $path: () => `${prefix}${prefix11}${PATH44}`
                          },
                          patch: (option: { body: Methods50['patch']['reqBody'], headers?: Methods50['patch']['reqHeaders'], config?: T }) =>
                            fetch<Methods50['patch']['resBody'], BasicHeaders, Methods50['patch']['status']>(prefix, prefix11, PATCH, option).json(),
                          $patch: (option: { body: Methods50['patch']['reqBody'], headers?: Methods50['patch']['reqHeaders'], config?: T }) =>
                            fetch<Methods50['patch']['resBody'], BasicHeaders, Methods50['patch']['status']>(prefix, prefix11, PATCH, option).json().then(r => r.body),
                          $path: () => `${prefix}${prefix11}`
                        }
                      },
                      validate: {
                        post: (option: { body: Methods52['post']['reqBody'], headers?: Methods52['post']['reqHeaders'], config?: T }) =>
                          fetch<void, BasicHeaders, Methods52['post']['status']>(prefix, `${prefix10}${PATH45}`, POST, option).send(),
                        $post: (option: { body: Methods52['post']['reqBody'], headers?: Methods52['post']['reqHeaders'], config?: T }) =>
                          fetch<void, BasicHeaders, Methods52['post']['status']>(prefix, `${prefix10}${PATH45}`, POST, option).send().then(r => r.body),
                        $path: () => `${prefix}${prefix10}${PATH45}`
                      },
                      get: (option: { query: Methods49['get']['query'], headers?: Methods49['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods49['get']['resBody'], BasicHeaders, Methods49['get']['status']>(prefix, `${prefix10}${PATH29}`, GET, option).json(),
                      $get: (option: { query: Methods49['get']['query'], headers?: Methods49['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods49['get']['resBody'], BasicHeaders, Methods49['get']['status']>(prefix, `${prefix10}${PATH29}`, GET, option).json().then(r => r.body),
                      post: (option: { body: Methods49['post']['reqBody'], headers?: Methods49['post']['reqHeaders'], config?: T }) =>
                        fetch<Methods49['post']['resBody'], BasicHeaders, Methods49['post']['status']>(prefix, `${prefix10}${PATH29}`, POST, option).json(),
                      $post: (option: { body: Methods49['post']['reqBody'], headers?: Methods49['post']['reqHeaders'], config?: T }) =>
                        fetch<Methods49['post']['resBody'], BasicHeaders, Methods49['post']['status']>(prefix, `${prefix10}${PATH29}`, POST, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods49['get']['query'] }) =>
                        `${prefix}${prefix10}${PATH29}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { headers?: Methods47['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods47['get']['resBody'], BasicHeaders, Methods47['get']['status']>(prefix, prefix10, GET, option).json(),
                    $get: (option?: { headers?: Methods47['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods47['get']['resBody'], BasicHeaders, Methods47['get']['status']>(prefix, prefix10, GET, option).json().then(r => r.body),
                    patch: (option: { body: Methods47['patch']['reqBody'], headers?: Methods47['patch']['reqHeaders'], config?: T }) =>
                      fetch<Methods47['patch']['resBody'], BasicHeaders, Methods47['patch']['status']>(prefix, prefix10, PATCH, option).json(),
                    $patch: (option: { body: Methods47['patch']['reqBody'], headers?: Methods47['patch']['reqHeaders'], config?: T }) =>
                      fetch<Methods47['patch']['resBody'], BasicHeaders, Methods47['patch']['status']>(prefix, prefix10, PATCH, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix10}`
                  }
                },
                suggestion: {
                  get: (option: { query: Methods53['get']['query'], headers?: Methods53['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods53['get']['resBody'], BasicHeaders, Methods53['get']['status']>(prefix, `${prefix1}${PATH46}`, GET, option).json(),
                  $get: (option: { query: Methods53['get']['query'], headers?: Methods53['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods53['get']['resBody'], BasicHeaders, Methods53['get']['status']>(prefix, `${prefix1}${PATH46}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods53['get']['query'] }) =>
                    `${prefix}${prefix1}${PATH46}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                get: (option: { query: Methods46['get']['query'], headers?: Methods46['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods46['get']['resBody'], BasicHeaders, Methods46['get']['status']>(prefix, `${prefix1}${PATH42}`, GET, option).json(),
                $get: (option: { query: Methods46['get']['query'], headers?: Methods46['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods46['get']['resBody'], BasicHeaders, Methods46['get']['status']>(prefix, `${prefix1}${PATH42}`, GET, option).json().then(r => r.body),
                post: (option: { body: Methods46['post']['reqBody'], headers?: Methods46['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods46['post']['resBody'], BasicHeaders, Methods46['post']['status']>(prefix, `${prefix1}${PATH42}`, POST, option).json(),
                $post: (option: { body: Methods46['post']['reqBody'], headers?: Methods46['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods46['post']['resBody'], BasicHeaders, Methods46['post']['status']>(prefix, `${prefix1}${PATH42}`, POST, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods46['get']['query'] }) =>
                  `${prefix}${prefix1}${PATH42}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              phone_number: {
                verification: {
                  post: (option: { body: Methods54['post']['reqBody'], headers?: Methods54['post']['reqHeaders'], config?: T }) =>
                    fetch<Methods54['post']['resBody'], BasicHeaders, Methods54['post']['status']>(prefix, `${prefix1}${PATH47}`, POST, option).json(),
                  $post: (option: { body: Methods54['post']['reqBody'], headers?: Methods54['post']['reqHeaders'], config?: T }) =>
                    fetch<Methods54['post']['resBody'], BasicHeaders, Methods54['post']['status']>(prefix, `${prefix1}${PATH47}`, POST, option).json().then(r => r.body),
                  patch: (option: { body: Methods54['patch']['reqBody'], headers?: Methods54['patch']['reqHeaders'], config?: T }) =>
                    fetch<void, BasicHeaders, Methods54['patch']['status']>(prefix, `${prefix1}${PATH47}`, PATCH, option).send(),
                  $patch: (option: { body: Methods54['patch']['reqBody'], headers?: Methods54['patch']['reqHeaders'], config?: T }) =>
                    fetch<void, BasicHeaders, Methods54['patch']['status']>(prefix, `${prefix1}${PATH47}`, PATCH, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH47}`
                }
              },
              statements: {
                details_csv: {
                  get: (option?: { query?: Methods56['get']['query'], headers?: Methods56['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods56['get']['resHeaders'], Methods56['get']['status']>(prefix, `${prefix1}${PATH49}`, GET, option).send(),
                  $get: (option?: { query?: Methods56['get']['query'], headers?: Methods56['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods56['get']['resHeaders'], Methods56['get']['status']>(prefix, `${prefix1}${PATH49}`, GET, option).send().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods56['get']['query'] }) =>
                    `${prefix}${prefix1}${PATH49}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                next_billing: {
                  get: (option?: { headers?: Methods57['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods57['get']['resBody'], BasicHeaders, Methods57['get']['status']>(prefix, `${prefix1}${PATH50}`, GET, option).json(),
                  $get: (option?: { headers?: Methods57['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods57['get']['resBody'], BasicHeaders, Methods57['get']['status']>(prefix, `${prefix1}${PATH50}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH50}`
                },
                get: (option: { query: Methods55['get']['query'], headers?: Methods55['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods55['get']['resBody'], BasicHeaders, Methods55['get']['status']>(prefix, `${prefix1}${PATH48}`, GET, option).json(),
                $get: (option: { query: Methods55['get']['query'], headers?: Methods55['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods55['get']['resBody'], BasicHeaders, Methods55['get']['status']>(prefix, `${prefix1}${PATH48}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods55['get']['query'] }) =>
                  `${prefix}${prefix1}${PATH48}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              user_block_lists: {
                post: (option: { body: Methods58['post']['reqBody'], headers?: Methods58['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods58['post']['resBody'], BasicHeaders, Methods58['post']['status']>(prefix, `${prefix1}${PATH51}`, POST, option).json(),
                $post: (option: { body: Methods58['post']['reqBody'], headers?: Methods58['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods58['post']['resBody'], BasicHeaders, Methods58['post']['status']>(prefix, `${prefix1}${PATH51}`, POST, option).json().then(r => r.body),
                delete: (option: { query: Methods58['delete']['query'], headers?: Methods58['delete']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods58['delete']['status']>(prefix, `${prefix1}${PATH51}`, DELETE, option).send(),
                $delete: (option: { query: Methods58['delete']['query'], headers?: Methods58['delete']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods58['delete']['status']>(prefix, `${prefix1}${PATH51}`, DELETE, option).send().then(r => r.body),
                $path: (option?: { method: 'delete'; query: Methods58['delete']['query'] }) =>
                  `${prefix}${prefix1}${PATH51}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              users: {
                _user_id: (val12: number) => {
                  const prefix12 = `${prefix1}${PATH34}/${val12}`

                  return {
                    badges: {
                      get: (option?: { headers?: Methods60['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods60['get']['resBody'], BasicHeaders, Methods60['get']['status']>(prefix, `${prefix12}${PATH52}`, GET, option).json(),
                      $get: (option?: { headers?: Methods60['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods60['get']['resBody'], BasicHeaders, Methods60['get']['status']>(prefix, `${prefix12}${PATH52}`, GET, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix12}${PATH52}`
                    },
                    emergency_contact: {
                      get: (option?: { headers?: Methods61['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods61['get']['resBody'], BasicHeaders, Methods61['get']['status']>(prefix, `${prefix12}${PATH53}`, GET, option).json(),
                      $get: (option?: { headers?: Methods61['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods61['get']['resBody'], BasicHeaders, Methods61['get']['status']>(prefix, `${prefix12}${PATH53}`, GET, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix12}${PATH53}`
                    },
                    graduates: {
                      post: (option?: { headers?: Methods62['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods62['post']['status']>(prefix, `${prefix12}${PATH54}`, POST, option).send(),
                      $post: (option?: { headers?: Methods62['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods62['post']['status']>(prefix, `${prefix12}${PATH54}`, POST, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix12}${PATH54}`
                    },
                    groups: {
                      put: (option: { body: Methods63['put']['reqBody'], headers?: Methods63['put']['reqHeaders'], config?: T }) =>
                        fetch<Methods63['put']['resBody'], BasicHeaders, Methods63['put']['status']>(prefix, `${prefix12}${PATH24}`, PUT, option).json(),
                      $put: (option: { body: Methods63['put']['reqBody'], headers?: Methods63['put']['reqHeaders'], config?: T }) =>
                        fetch<Methods63['put']['resBody'], BasicHeaders, Methods63['put']['status']>(prefix, `${prefix12}${PATH24}`, PUT, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix12}${PATH24}`
                    },
                    offerings: {
                      get: (option: { query: Methods64['get']['query'], headers?: Methods64['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods64['get']['resBody'], BasicHeaders, Methods64['get']['status']>(prefix, `${prefix12}${PATH29}`, GET, option).json(),
                      $get: (option: { query: Methods64['get']['query'], headers?: Methods64['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods64['get']['resBody'], BasicHeaders, Methods64['get']['status']>(prefix, `${prefix12}${PATH29}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods64['get']['query'] }) =>
                        `${prefix}${prefix12}${PATH29}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    reviews_from_client: {
                      get: (option: { query: Methods65['get']['query'], headers?: Methods65['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods65['get']['resBody'], BasicHeaders, Methods65['get']['status']>(prefix, `${prefix12}${PATH55}`, GET, option).json(),
                      $get: (option: { query: Methods65['get']['query'], headers?: Methods65['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods65['get']['resBody'], BasicHeaders, Methods65['get']['status']>(prefix, `${prefix12}${PATH55}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods65['get']['query'] }) =>
                        `${prefix}${prefix12}${PATH55}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    skills: {
                      get: (option?: { headers?: Methods66['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods66['get']['resBody'], BasicHeaders, Methods66['get']['status']>(prefix, `${prefix12}${PATH56}`, GET, option).json(),
                      $get: (option?: { headers?: Methods66['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods66['get']['resBody'], BasicHeaders, Methods66['get']['status']>(prefix, `${prefix12}${PATH56}`, GET, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix12}${PATH56}`
                    },
                    get: (option?: { headers?: Methods59['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods59['get']['resBody'], BasicHeaders, Methods59['get']['status']>(prefix, prefix12, GET, option).json(),
                    $get: (option?: { headers?: Methods59['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods59['get']['resBody'], BasicHeaders, Methods59['get']['status']>(prefix, prefix12, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix12}`
                  }
                }
              },
              get: (option?: { headers?: Methods11['get']['reqHeaders'], config?: T }) =>
                fetch<Methods11['get']['resBody'], BasicHeaders, Methods11['get']['status']>(prefix, prefix1, GET, option).json(),
              $get: (option?: { headers?: Methods11['get']['reqHeaders'], config?: T }) =>
                fetch<Methods11['get']['resBody'], BasicHeaders, Methods11['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
              patch: (option: { body: Methods11['patch']['reqBody'], headers?: Methods11['patch']['reqHeaders'], config?: T }) =>
                fetch<Methods11['patch']['resBody'], BasicHeaders, Methods11['patch']['status']>(prefix, prefix1, PATCH, option).json(),
              $patch: (option: { body: Methods11['patch']['reqBody'], headers?: Methods11['patch']['reqHeaders'], config?: T }) =>
                fetch<Methods11['patch']['resBody'], BasicHeaders, Methods11['patch']['status']>(prefix, prefix1, PATCH, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix1}`
            }
          }
        },
        companies: {
          _company_id: (val13: number) => {
            const prefix13 = `${PATH57}/${val13}`

            return {
              base: {
                get: (option?: { headers?: Methods68['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods68['get']['resBody'], BasicHeaders, Methods68['get']['status']>(prefix, `${prefix13}${PATH13}`, GET, option).json(),
                $get: (option?: { headers?: Methods68['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods68['get']['resBody'], BasicHeaders, Methods68['get']['status']>(prefix, `${prefix13}${PATH13}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix13}${PATH13}`
              },
              clients: {
                get: (option?: { headers?: Methods69['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods69['get']['resBody'], BasicHeaders, Methods69['get']['status']>(prefix, `${prefix13}${PATH58}`, GET, option).json(),
                $get: (option?: { headers?: Methods69['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods69['get']['resBody'], BasicHeaders, Methods69['get']['status']>(prefix, `${prefix13}${PATH58}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix13}${PATH58}`
              },
              confirmed_billing_operations: {
                _confirmed_billing_operation_id: (val14: number) => {
                  const prefix14 = `${prefix13}${PATH21}/${val14}`

                  return {
                    detail_pdf: {
                      get: (option?: { headers?: Methods70['get']['reqHeaders'], config?: T }) =>
                        fetch<void, Methods70['get']['resHeaders'], Methods70['get']['status']>(prefix, `${prefix14}${PATH22}`, GET, option).send(),
                      $get: (option?: { headers?: Methods70['get']['reqHeaders'], config?: T }) =>
                        fetch<void, Methods70['get']['resHeaders'], Methods70['get']['status']>(prefix, `${prefix14}${PATH22}`, GET, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix14}${PATH22}`
                    }
                  }
                }
              },
              statements: {
                billing_adjustments_csv: {
                  get: (option?: { query?: Methods72['get']['query'], headers?: Methods72['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods72['get']['resHeaders'], Methods72['get']['status']>(prefix, `${prefix13}${PATH59}`, GET, option).send(),
                  $get: (option?: { query?: Methods72['get']['query'], headers?: Methods72['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods72['get']['resHeaders'], Methods72['get']['status']>(prefix, `${prefix13}${PATH59}`, GET, option).send().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods72['get']['query'] }) =>
                    `${prefix}${prefix13}${PATH59}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                billing_transfer_commissions_csv: {
                  get: (option?: { query?: Methods73['get']['query'], headers?: Methods73['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods73['get']['resHeaders'], Methods73['get']['status']>(prefix, `${prefix13}${PATH60}`, GET, option).send(),
                  $get: (option?: { query?: Methods73['get']['query'], headers?: Methods73['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods73['get']['resHeaders'], Methods73['get']['status']>(prefix, `${prefix13}${PATH60}`, GET, option).send().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods73['get']['query'] }) =>
                    `${prefix}${prefix13}${PATH60}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                client_statements_csv: {
                  get: (option?: { query?: Methods74['get']['query'], headers?: Methods74['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods74['get']['resHeaders'], Methods74['get']['status']>(prefix, `${prefix13}${PATH61}`, GET, option).send(),
                  $get: (option?: { query?: Methods74['get']['query'], headers?: Methods74['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods74['get']['resHeaders'], Methods74['get']['status']>(prefix, `${prefix13}${PATH61}`, GET, option).send().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods74['get']['query'] }) =>
                    `${prefix}${prefix13}${PATH61}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                company_statements_csv: {
                  get: (option?: { query?: Methods75['get']['query'], headers?: Methods75['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods75['get']['resHeaders'], Methods75['get']['status']>(prefix, `${prefix13}${PATH62}`, GET, option).send(),
                  $get: (option?: { query?: Methods75['get']['query'], headers?: Methods75['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods75['get']['resHeaders'], Methods75['get']['status']>(prefix, `${prefix13}${PATH62}`, GET, option).send().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods75['get']['query'] }) =>
                    `${prefix}${prefix13}${PATH62}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                details: {
                  by_client: {
                    get: (option: { query: Methods77['get']['query'], headers?: Methods77['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods77['get']['resBody'], BasicHeaders, Methods77['get']['status']>(prefix, `${prefix13}${PATH64}`, GET, option).json(),
                    $get: (option: { query: Methods77['get']['query'], headers?: Methods77['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods77['get']['resBody'], BasicHeaders, Methods77['get']['status']>(prefix, `${prefix13}${PATH64}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods77['get']['query'] }) =>
                      `${prefix}${prefix13}${PATH64}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  },
                  of_client_csv: {
                    get: (option?: { query?: Methods78['get']['query'], headers?: Methods78['get']['reqHeaders'], config?: T }) =>
                      fetch<void, Methods78['get']['resHeaders'], Methods78['get']['status']>(prefix, `${prefix13}${PATH65}`, GET, option).send(),
                    $get: (option?: { query?: Methods78['get']['query'], headers?: Methods78['get']['reqHeaders'], config?: T }) =>
                      fetch<void, Methods78['get']['resHeaders'], Methods78['get']['status']>(prefix, `${prefix13}${PATH65}`, GET, option).send().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods78['get']['query'] }) =>
                      `${prefix}${prefix13}${PATH65}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  },
                  get: (option?: { query?: Methods76['get']['query'], headers?: Methods76['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods76['get']['resBody'], BasicHeaders, Methods76['get']['status']>(prefix, `${prefix13}${PATH63}`, GET, option).json(),
                  $get: (option?: { query?: Methods76['get']['query'], headers?: Methods76['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods76['get']['resBody'], BasicHeaders, Methods76['get']['status']>(prefix, `${prefix13}${PATH63}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods76['get']['query'] }) =>
                    `${prefix}${prefix13}${PATH63}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                get: (option: { query: Methods71['get']['query'], headers?: Methods71['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods71['get']['resBody'], BasicHeaders, Methods71['get']['status']>(prefix, `${prefix13}${PATH48}`, GET, option).json(),
                $get: (option: { query: Methods71['get']['query'], headers?: Methods71['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods71['get']['resBody'], BasicHeaders, Methods71['get']['status']>(prefix, `${prefix13}${PATH48}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods71['get']['query'] }) =>
                  `${prefix}${prefix13}${PATH48}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              worker_list_storages: {
                _worker_list_storage_id: (val15: number) => {
                  const prefix15 = `${prefix13}${PATH66}/${val15}`

                  return {
                    delete: (option?: { headers?: Methods80['delete']['reqHeaders'], config?: T }) =>
                      fetch<void, BasicHeaders, Methods80['delete']['status']>(prefix, prefix15, DELETE, option).send(),
                    $delete: (option?: { headers?: Methods80['delete']['reqHeaders'], config?: T }) =>
                      fetch<void, BasicHeaders, Methods80['delete']['status']>(prefix, prefix15, DELETE, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix15}`
                  }
                },
                get: (option?: { headers?: Methods79['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods79['get']['resBody'], BasicHeaders, Methods79['get']['status']>(prefix, `${prefix13}${PATH66}`, GET, option).json(),
                $get: (option?: { headers?: Methods79['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods79['get']['resBody'], BasicHeaders, Methods79['get']['status']>(prefix, `${prefix13}${PATH66}`, GET, option).json().then(r => r.body),
                post: (option: { body: Methods79['post']['reqBody'], headers?: Methods79['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods79['post']['status']>(prefix, `${prefix13}${PATH66}`, POST, option).send(),
                $post: (option: { body: Methods79['post']['reqBody'], headers?: Methods79['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods79['post']['status']>(prefix, `${prefix13}${PATH66}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix13}${PATH66}`
              },
              get: (option?: { headers?: Methods67['get']['reqHeaders'], config?: T }) =>
                fetch<Methods67['get']['resBody'], BasicHeaders, Methods67['get']['status']>(prefix, prefix13, GET, option).json(),
              $get: (option?: { headers?: Methods67['get']['reqHeaders'], config?: T }) =>
                fetch<Methods67['get']['resBody'], BasicHeaders, Methods67['get']['status']>(prefix, prefix13, GET, option).json().then(r => r.body),
              patch: (option: { body: Methods67['patch']['reqBody'], headers?: Methods67['patch']['reqHeaders'], config?: T }) =>
                fetch<Methods67['patch']['resBody'], BasicHeaders, Methods67['patch']['status']>(prefix, prefix13, PATCH, option).json(),
              $patch: (option: { body: Methods67['patch']['reqBody'], headers?: Methods67['patch']['reqHeaders'], config?: T }) =>
                fetch<Methods67['patch']['resBody'], BasicHeaders, Methods67['patch']['status']>(prefix, prefix13, PATCH, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix13}`
            }
          }
        },
        master_company_occupations: {
          get: (option?: { headers?: Methods81['get']['reqHeaders'], config?: T }) =>
            fetch<Methods81['get']['resBody'], BasicHeaders, Methods81['get']['status']>(prefix, PATH67, GET, option).json(),
          $get: (option?: { headers?: Methods81['get']['reqHeaders'], config?: T }) =>
            fetch<Methods81['get']['resBody'], BasicHeaders, Methods81['get']['status']>(prefix, PATH67, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH67}`
        },
        notices: {
          _notice_id: (val16: number) => {
            const prefix16 = `${PATH68}/${val16}`

            return {
              read: {
                post: (option?: { headers?: Methods83['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods83['post']['status']>(prefix, `${prefix16}${PATH16}`, POST, option).send(),
                $post: (option?: { headers?: Methods83['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods83['post']['status']>(prefix, `${prefix16}${PATH16}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix16}${PATH16}`
              }
            }
          },
          get: (option: { query: Methods82['get']['query'], headers?: Methods82['get']['reqHeaders'], config?: T }) =>
            fetch<Methods82['get']['resBody'], BasicHeaders, Methods82['get']['status']>(prefix, PATH68, GET, option).json(),
          $get: (option: { query: Methods82['get']['query'], headers?: Methods82['get']['reqHeaders'], config?: T }) =>
            fetch<Methods82['get']['resBody'], BasicHeaders, Methods82['get']['status']>(prefix, PATH68, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get'; query: Methods82['get']['query'] }) =>
            `${prefix}${PATH68}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        occupations: {
          _occupation_id: (val17: number) => {
            const prefix17 = `${PATH69}/${val17}`

            return {
              skills: {
                get: (option?: { headers?: Methods85['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods85['get']['resBody'], BasicHeaders, Methods85['get']['status']>(prefix, `${prefix17}${PATH56}`, GET, option).json(),
                $get: (option?: { headers?: Methods85['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods85['get']['resBody'], BasicHeaders, Methods85['get']['status']>(prefix, `${prefix17}${PATH56}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix17}${PATH56}`
              }
            }
          },
          get: (option?: { headers?: Methods84['get']['reqHeaders'], config?: T }) =>
            fetch<Methods84['get']['resBody'], BasicHeaders, Methods84['get']['status']>(prefix, PATH69, GET, option).json(),
          $get: (option?: { headers?: Methods84['get']['reqHeaders'], config?: T }) =>
            fetch<Methods84['get']['resBody'], BasicHeaders, Methods84['get']['status']>(prefix, PATH69, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH69}`
        },
        sign_in: {
          confirm: {
            get: (option?: { headers?: Methods87['get']['reqHeaders'], config?: T }) =>
              fetch<Methods87['get']['resBody'], BasicHeaders, Methods87['get']['status']>(prefix, PATH71, GET, option).json(),
            $get: (option?: { headers?: Methods87['get']['reqHeaders'], config?: T }) =>
              fetch<Methods87['get']['resBody'], BasicHeaders, Methods87['get']['status']>(prefix, PATH71, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH71}`
          },
          refresh: {
            patch: (option?: { headers?: Methods88['patch']['reqHeaders'], config?: T }) =>
              fetch<void, Methods88['patch']['resHeaders'], Methods88['patch']['status']>(prefix, PATH72, PATCH, option).send(),
            $patch: (option?: { headers?: Methods88['patch']['reqHeaders'], config?: T }) =>
              fetch<void, Methods88['patch']['resHeaders'], Methods88['patch']['status']>(prefix, PATH72, PATCH, option).send().then(r => r.body),
            $path: () => `${prefix}${PATH72}`
          },
          post: (option: { body: Methods86['post']['reqBody'], headers?: Methods86['post']['reqHeaders'], config?: T }) =>
            fetch<void, Methods86['post']['resHeaders'], Methods86['post']['status']>(prefix, PATH70, POST, option).send(),
          $post: (option: { body: Methods86['post']['reqBody'], headers?: Methods86['post']['reqHeaders'], config?: T }) =>
            fetch<void, Methods86['post']['resHeaders'], Methods86['post']['status']>(prefix, PATH70, POST, option).send().then(r => r.body),
          $path: () => `${prefix}${PATH70}`
        },
        sign_out: {
          delete: (option?: { headers?: Methods89['delete']['reqHeaders'], config?: T }) =>
            fetch<Methods89['delete']['resBody'], Methods89['delete']['resHeaders'], Methods89['delete']['status']>(prefix, PATH73, DELETE, option).json(),
          $delete: (option?: { headers?: Methods89['delete']['reqHeaders'], config?: T }) =>
            fetch<Methods89['delete']['resBody'], Methods89['delete']['resHeaders'], Methods89['delete']['status']>(prefix, PATH73, DELETE, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH73}`
        },
        tags: {
          get: (option?: { headers?: Methods90['get']['reqHeaders'], config?: T }) =>
            fetch<Methods90['get']['resBody'], BasicHeaders, Methods90['get']['status']>(prefix, PATH74, GET, option).json(),
          $get: (option?: { headers?: Methods90['get']['reqHeaders'], config?: T }) =>
            fetch<Methods90['get']['resBody'], BasicHeaders, Methods90['get']['status']>(prefix, PATH74, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH74}`
        }
      },
      v2: {
        clients: {
          _client_id: (val18: number) => {
            const prefix18 = `${PATH75}/${val18}`

            return {
              offerings: {
                _offering_id: (val19: number) => {
                  const prefix19 = `${prefix18}${PATH29}/${val19}`

                  return {
                    users: {
                      reviews: {
                        post: (option: { body: Methods91['post']['reqBody'], headers?: Methods91['post']['reqHeaders'], config?: T }) =>
                          fetch<void, BasicHeaders, Methods91['post']['status']>(prefix, `${prefix19}${PATH76}`, POST, option).send(),
                        $post: (option: { body: Methods91['post']['reqBody'], headers?: Methods91['post']['reqHeaders'], config?: T }) =>
                          fetch<void, BasicHeaders, Methods91['post']['status']>(prefix, `${prefix19}${PATH76}`, POST, option).send().then(r => r.body),
                        $path: () => `${prefix}${prefix19}${PATH76}`
                      }
                    }
                  }
                }
              },
              users: {
                get: (option?: { query?: Methods92['get']['query'], headers?: Methods92['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods92['get']['resBody'], BasicHeaders, Methods92['get']['status']>(prefix, `${prefix18}${PATH34}`, GET, option).json(),
                $get: (option?: { query?: Methods92['get']['query'], headers?: Methods92['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods92['get']['resBody'], BasicHeaders, Methods92['get']['status']>(prefix, `${prefix18}${PATH34}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods92['get']['query'] }) =>
                  `${prefix}${prefix18}${PATH34}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              }
            }
          }
        }
      }
    }
  }
}

export type ApiInstance = ReturnType<typeof api>
export default api
