import { fixedPhrase } from '~/constants/fixedPhrase';
import { Message } from '~/types/message';
import { isAxiosError } from '~/utils/error/isAxiosError';
import { isErrorWithMessage, messagesOfMessageError } from '~/utils/error/isErrorWithMessage';
import { isNotFoundError } from '~/utils/error/isNotFoundError';
import { isServerError } from '~/utils/error/isServerError';

const buildErrorMessage = (body: string[]): Message => ({
  type: 'error',
  body: body,
});

const UNKNOWN_ERROR_MESSAGE: Message = {
  type: 'error',
  body: [fixedPhrase.unknownError],
};

// APIクライアントの例外のエラーをメッセージの型に変換する
export const errorToMessage = (error: unknown): Message => {
  if (!isAxiosError(error)) {
    return UNKNOWN_ERROR_MESSAGE;
  }

  return error.response === undefined
    ? buildErrorMessage([fixedPhrase.networkError])
    : isNotFoundError(error)
    ? buildErrorMessage([fixedPhrase.notFoundError])
    : isErrorWithMessage(error)
    ? buildErrorMessage(messagesOfMessageError(error)) // レスポンスに error か errors の少なくとも一方が含まれていることが isErrorWithMessage の判定で保証される
    : isServerError(error)
    ? buildErrorMessage([fixedPhrase.serverError])
    : UNKNOWN_ERROR_MESSAGE;
};
